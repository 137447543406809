import React, { memo, useEffect, useRef } from 'react'
import { Route, Switch } from 'react-router'
import { PrivateRoutes } from './Private/PrivateRoutes'
import Auth from './Public/Auth'
import Layout from '../Layout/Layout'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'

const TrackPath = () => {
  const location = useLocation()
  const prevPath = useRef(null)

  useEffect(() => {
    if (location.pathname !== '/' && prevPath.current !== location.pathname) {
      localStorage.setItem('last_path', location.pathname)
      prevPath.current = location.pathname
    }
  }, [location.pathname])

  return null
}

const AppRouter = () => {
  return (
    <Router>
      <TrackPath />
      <Switch>
        <Route path="/app">
          <Layout>
            <PrivateRoutes />
          </Layout>
        </Route>
        <Route path="/">
          <Auth />
        </Route>
      </Switch>
    </Router>
  )
}

export default memo(AppRouter)
