import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSidebar, clinicAdmin, dentistSidebar } from './data/data'
import './sidebar.css'

import CloseSidebarItem from './components/CloseSidebarItems'
import OpenSidebarItem from './components/OpenSidebarItems'
import SmileIcon from './Icons/SmileIcon'
import KeepSmilingLogo from './Icons/KeepSmilingLogo'
import { useLocation } from 'react-router-dom'
import apiClient from '../../utils/backend'

const Sidebar = () => {
  const { href } = window.location
  const location = useLocation()
  const dispatch = useDispatch()
  const { collapsed, userType } = useSelector((state) => ({
    collapsed: state.sidebarReducer.collapsed,
    userType: state.userReducer.typeId,
  }))
  const [sidebarState, setSidebarState] = useState(null)
  const [smilePayOptionsDentist, setSmilePayOptionsDentist] = useState([])

  useEffect(() => {
    if (!userType) return
    if (userType === 1) return setSidebarState([...adminSidebar])
    if (userType === 2) {
      setSidebarState(
        dentistSidebar.map((item) =>
          item?.title === 'SmilePay'
            ? { ...item, subItems: smilePayOptionsDentist }
            : item
        )
      )
    }
    if ((userType === 6) | (userType === 7))
      return setSidebarState([...clinicAdmin])
    // throw new Error('User type is not identified')
  }, [userType, smilePayOptionsDentist])

  // Closes Sidebar on Route change
  useEffect(() => {
    if (userType === 1) return
    if (collapsed) return
    dispatch({ type: 'COLLAPSE' })
  }, [location])

  //Delete last search
  useEffect(() => {
    const routes = [
      'odontologo',
      'odontologos',
      'treatment-stages',
      'historia-clinica',
      'mis-pacientes',
      'home',
      'asesores',
    ]
    const path = href.split('/')
    const shouldDeleteLastSearch = !routes.some((route) => path.includes(route))
    if (shouldDeleteLastSearch) {
      localStorage.removeItem('lastSearch')
    }
  }, [location])

  useEffect(() => {
    let isMounted = true

    const getDentistSmilePayItems = async () => {
      try {
        const { data } = await apiClient.get('api/helper/getSidebarMenu')
        const serializedData = data
          .filter((item) => item.visible === 1)
          .map((item) => ({
            ...item,
            active: true,
          }))

        if (isMounted) {
          setSmilePayOptionsDentist(serializedData)
        }
      } catch (error) {
        console.error('Error al obtener el sidebar:', error)
      }
    }

    getDentistSmilePayItems()

    return () => {
      isMounted = false
    }
  }, [])

  if (!sidebarState) return <div id="sidebar" className="collapsed" />
  return (
    <div id="sidebar" className={`side-container ${collapsed && 'collapsed'}`}>
      <div className="d-flex flex-column">
        {collapsed ? (
          <div className="d-flex flex-column" style={{ marginTop: '120px' }}>
            {sidebarState.map((item) => (
              <CloseSidebarItem item={item} key={item.title} />
            ))}
          </div>
        ) : (
          <>
            <KeepSmilingLogo />
            {sidebarState.map((item) => (
              <OpenSidebarItem item={item} key={item.title} />
            ))}
          </>
        )}
      </div>

      {collapsed && <SmileIcon />}
      {!collapsed && userType === 1 && (
        <div
          className=" text-center mb-2 text-white d-flex align-items-center justify-content-center gap-1 flex-wrap"
          style={{ opacity: 0.7, fontSize: '13px' }}
        >
          <p className="m-0">Hecho con </p>
          <span className="fs-4"> ♥ </span>
          <p className="m-0">por un gran equipo</p>
        </div>
      )}
    </div>
  )
}

export default Sidebar
