import { roles } from '../../config'

// eslint-disable-next-line
export default [
  {
    component: () =>
      import(
        '../../../pages/Admin/Procesos/PaymentValidation/PaymentValidation'
      ),
    path: '/procesos/validacionPagos',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Odontologo'),
    path: '/odontologos',
    exact: true,
    permission: [roles.ADMIN, roles.BRANCH_ADMIN, roles.ENTITY_ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/Odontologo/CreateDentist/CreateDentist'),
    path: '/odontologos/crear/nuevo',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/Odontologo/EditDentist/EditDentist'),
    path: '/editar/odontologo/:id',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Entidades/Entities'),
    path: '/entidades',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Entidades/CreateEntity'),
    path: '/entidades/crear',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Sucursales/BranchOffices'),
    path: '/sucursales',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Sucursales/BranchOffices'),
    path: '/sucursales/:entityId',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/Procesos/RegistroOriginal/RegistroOriginal'),
    path: '/procesos/escaneo',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Procesos/Movement/Movement'),
    path: '/procesos/movimiento',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Procesos/Proposal/Proposal'),
    path: '/procesos/propuesta',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Notifications'),
    path: '/admin/notificaciones',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/Procesos/ProposalControl/ProposalControl'),
    path: '/procesos/controlCalidad',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/Procesos/ProcessesDispatch/Dispatch'),
    path: '/procesos/despacho',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Ingresos'),
    path: '/ingresos/',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Solicitud'),
    path: '/ingresos/nuevo',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Referrals/Referrals'),
    path: '/derivaciones',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Pacientes'),
    path: '/pacientes/:odId?',
    exact: true,
    permission: [roles.ADMIN],
  },

  {
    component: () => import('../../../pages/Admin/Reclamos/Claims'),
    path: '/reclamos',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Usuarios/index'),
    path: '/usuarios',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Tratamientos'),
    path: '/tratamientos',
    exact: true,
    permission: [roles.ADMIN, roles.BRANCH_ADMIN, roles.ENTITY_ADMIN],
  },
  {
    component: () => import('../../../pages/FormularioOnline'),
    path: '/tratamiento/:id',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/FormularioOnline/admin'),
    path: '/tratamiento-view',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/Asesores/Squads'),
    path: '/admin/asesores',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/Asesores/AddDentistToAdvisor'),
    path: '/admin/añadir-dentista-al-asesor-:id',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/SmilePay/MacroPaymentIndex'),
    path: '/pagos/macro',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Admin/SmileCreator/SmileCreator'),
    path: '/admin/smileCreator/:dentistId',
    exact: true,
    permission: [roles.MARKETING],
  },
  {
    component: () => import('../../../pages/Admin/MRHistory/MRHistory'),
    path: '/admin/historias-clinicas/:patientId',
    exact: true,
    permission: [roles.ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Admin/ComercialPanel/ComercialPanel'),
    path: '/admin/panel-comercial',
    exact: true,
    permission: [roles.ADMIN, roles.MARKETING, roles.ATENTION],
  },
  {
    component: () =>
      import(
        '../../../pages/Admin/AdministrationSidebar/AdministrationSidebar'
      ),
    path: '/pagos/administrar-sidebar',
    exact: true,
    permission: [roles.COBRANZAS],
  },
]
