import { intersection } from 'lodash'
import { useSelector } from 'react-redux'

export function GetAllowedRoutes(routes) {
  const { userProfile } = useSelector((reduxState) => reduxState.userReducer)
  const getTypeId = parseInt(JSON.parse(localStorage.getItem('type_user')))

  let roles = []
  if (getTypeId === 1) {
    roles.push('ADMIN')
  }
  if (getTypeId === 2) {
    roles.push('DENTIST')
  }
  if (getTypeId === 6) {
    roles.push('ENTITY_ADMIN')
  }
  if (getTypeId === 7) {
    roles.push('BRANCH_ADMIN')
  }
  if (userProfile === 2) {
    roles.push('ATENTION_USER')
  }
  if (userProfile === 3) {
    roles.push('MARKETING_USER')
  }
  if (userProfile === 5) {
    roles.push('COBRANZAS_USER')
  }

  return routes.filter(({ permission }) => {
    if (!permission) return true
    else if (!isArrayWithLength(permission)) return true
    else return intersection(permission, roles).length
  })
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length
}
