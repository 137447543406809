/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { startLogin } from '../../redux/actions/user'
import { ChangePassword } from './ChangePassword'
import { ResetPassword } from './ResetPassword'
import logo from '../../assets/images/ks-smile.png'
import { Eye, EyeOff } from 'react-feather'
import styles from './loginStyles.module.css'
import './login.css'

export const Login = (props) => {
  const {
    dispatch,
    email,
    password,
    error,
    setError,
    setView,
    handleInputChange,
    forgotEmail,
    handleRecoverPassword,
    view,
  } = props
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => {
    let input = document.getElementById('loginPassword')
    if (input.type === 'password') {
      input.type = 'text'
    } else {
      input.type = 'password'
    }
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    const userSession = localStorage.getItem('lgt')
    const typeUser = Number(localStorage.getItem('type_user')) // Convertir a número directamente
    const lastPath =
      localStorage.getItem('last_path') ||
      (typeUser === 1 ? '/app/ingresos' : '/app/home')

    if (userSession && typeUser) {
      history.replace(lastPath)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main
      onKeyPress={(e) => {
        e.key === 'Enter' && dispatch(startLogin(email, password))
      }}
      className="login-container h-100"
    >
      <div className="login-banner-top bg-primary px-4 py-5">
        <div className="max-width-600-login d-flex mx-auto">
          <div className="mx-300">
            <p className="h1 text-white fw-bold mb-3">
              Somos creadores de Sonrisas
            </p>
            <img
              src={logo}
              alt="keepsmiling logo"
              className="img img-fluid w-75"
            />
          </div>
        </div>
      </div>

      <div
        className="w-100 mx-auto px-4 align-middle text-left max-width-600-login"
        style={{ alignItems: 'center' }}
      >
        {view === 'login' && (
          <div className="responsive-margin-top">
            <div className="w-100 mb-5">
              <p className="h1 fw-bold text-primary">KeepSmiling</p>
            </div>
            {error.error ? (
              <div className="alert text-danger text-center" role="alert">
                {error.message}
              </div>
            ) : null}

            <Formik
              initialValues={{ email: '', password: '' }}
              validate={(values) => {
                const errors = {}
                if (!values.email) {
                  errors.email = 'Campo requerido'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Email inválido'
                }

                if (!values.password) {
                  errors.password = 'Campo requerido'
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                localStorage.removeItem('lgt')
                setError({ error: false, message: '' })
                try {
                  await dispatch(startLogin(values.email, values.password))
                  setError({ error: false, message: '' })
                } catch (error) {
                  setSubmitting(false)
                  return setError({
                    error: true,
                    message:
                      'Hubo un error al ingresar, por favor intente nuevamente.',
                  })
                }
              }}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className={`${
                      errors.email
                        ? 'form-control border border-1 border-danger rounded-3 mb-1'
                        : 'form-control'
                    }`}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="email"
                    component="div"
                    value={email}
                  />
                  <div className="d-flex w-100">
                    <div className="w-100">
                      <label htmlFor="loginPassword" className="mt-2">
                        Contraseña
                      </label>
                      <Field
                        type="password"
                        name="password"
                        id="loginPassword"
                        className={`${
                          errors.password
                            ? 'form-control border border-1 border-danger rounded-3 mb-1'
                            : 'form-control'
                        }`}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="password"
                        component="div"
                        value={password}
                      />
                    </div>
                    <div style={{ marginLeft: '-32px' }}>
                      {showPassword ? (
                        <Eye
                          className={styles.eye}
                          onClick={() => handleShowPassword()}
                        />
                      ) : (
                        <EyeOff
                          className={styles.eye}
                          onClick={() => handleShowPassword()}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center mt-3">
                    <div
                      className="btn text-muted"
                      onClick={() => setView('reset_password')}
                    >
                      ¡Olvidé mi contraseña!
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center my-3">
                    {!isSubmitting && (
                      <button
                        disabled={errors.email || errors.password}
                        type="submit"
                        className="btn btn-primary px-5"
                      >
                        Ingresar
                      </button>
                    )}
                    {isSubmitting && (
                      <button
                        className="btn btn-primary px-6"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {view === 'reset_password' && (
          <div className="px-lg-3 responsive-margin-top">
            <ResetPassword
              setView={setView}
              error={error}
              handleInputChange={handleInputChange}
              forgotEmail={forgotEmail}
              handleRecoverPassword={handleRecoverPassword}
            />
          </div>
        )}

        {view === 'change_password' && (
          <div className="px-lg-3 responsive-margin-top">
            <ChangePassword
              error={error}
              setError={setError}
              setView={setView}
              dispatch={dispatch}
            />
          </div>
        )}
      </div>

      <div className="login-banner-bottom bg-primary px-5">
        <div className="w-75 d-flex flex-column" style={{ marginTop: '15rem' }}>
          <p className="h1 text-white fw-bold mb-3">
            Somos creadores de Sonrisas
          </p>
          <img
            src={logo}
            alt="keepsmiling logo"
            className="img img-fluid w-75"
          />
        </div>
      </div>
    </main>
  )
}
