import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import apiClient from '../../../utils/backend'
export const AllBanks = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [image, setImage] = useState(null)

  const getImage = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get('api/qrPayments')
      const backendImage = data.find((i) => i.title === 'Todos los Bancos')
      setImage(backendImage.path ? backendImage.path : null)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (!isOpen) return
    getImage()
  }, [isOpen])
  return (
    <>
      <div className="text-white position-relative">
        <p
          className="py-2 my-2 my-1 cursor-pointer scale disabled sidebarItem"
          onClick={() => setIsOpen(true)}
          id="Todos los Bancos"
        >
          Todos los Bancos
        </p>
      </div>
      <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <h3 className="text-primary fw-bold m-0">Todos los Bancos</h3>
            <hr className="w-100" />
            {isLoading || !image ? (
              <Spinner />
            ) : (
              <img alt="QR" src={image} style={{ maxWidth: '400px' }} />
            )}
          </div>
        </ModalBody>
        <ModalFooter className="fst-italic disabled fs-7 pe-4">
          * Escanear este QR con MODO o Aplicacion Bancaria
        </ModalFooter>
      </Modal>
    </>
  )
}
