import apiClient from '../../utils/backend'

export const startLogin = (email, password) => {
  if (!email && !password) return
  return async (dispatch) => {
    const res = await apiClient.post('api/login', { email, password })

    if (res.data.data.token) {
      let d = res.data.data
      localStorage.setItem('lgt', d.token)
      localStorage.setItem('user_id', d.id)
      localStorage.setItem('profile', d.profile_id)
      localStorage.setItem('type_user', d.type?.id)
      localStorage.setItem('country_id', d.country_id)

      let user = {
        dentistId: d.dentist?.id,
        id: d.id,
        token: d.token,
        email: d.email,
        gender: d.gender,
        name: d.name,
        surname: d.surname,
        profile: d.profile_id,
        country: d.country_id,
        birthdate: d.birthdate,
        smilecreator: d.dentist?.smilecreator_category,
        smilecreator_points: d.dentist?.smile_creator_points,
        branch: d.dentist?.user?.groups,
        typeId: d.type.id,
        managed_entity: d.managed_entity,
        managed_branch: d.managed_branch,
        userProfile: d.profile?.id,
      }
      dispatch(login(user))
      handleRedirect(d.type?.id)
    } else {
      console.log(res, 'con error')
    }
  }
}

const login = (user) => ({ type: 'LOGIN', payload: user })

const handleRedirect = (type) => {
  const lastPath = localStorage.getItem('last_path')
  if (type === 1) {
    window.location.href = lastPath ? lastPath : '/app/ingresos'
  } else {
    window.location.href = lastPath ? lastPath : '/app/home'
  }
}
